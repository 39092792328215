/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger, Modal } from '@nextui-org/react';
import { IconChevronDown, IconMenu2, IconChevronRight } from '@tabler/icons-react';
import { AuthContext } from '../context';
import { CroppedLogo, Logo } from '../assets/images';
import { classNames, Constants } from '../utils';
import Conversion from './conversion';
import { MENU_ADMIN, MENU_MARKETING } from './menu';

const LEVEL = Constants.USER.LEVELS;

const Layout = () => {
  const { conversion, user } = useContext(AuthContext);
  const location = useLocation();
  const nav = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [navigation, setNavigation] = useState([]);
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  const routeName = breadcrumbs.find(x => {
    const currentParts = location.pathname.split('/');
    const pathParts = x?.href?.split('/') || [];

    if (currentParts.length !== pathParts.length) return false;

    return pathParts.every((item, i) => {
      const _current = currentParts[i];
      return pathParts[i].includes(':') ? !isNaN(+_current) : item === _current;
    });
  })?.name || '';

  const toggleSidebar = () => {
    const items = document.getElementsByClassName('desk-sidebar');
    for (let key = 0; key < items.length; key++) {
      items.item(key).classList.toggle('open');
    }
  }

  const getUserMenu = () => {
    // No está loggeado
    if (!user || ![LEVEL.ADMIN, LEVEL.MODERATOR, LEVEL.MARKETING].includes(user.level_id))
      return;

    const breadcrumbs = [];

    const searchSubmodules = (item) => {
        if (item?.href) breadcrumbs.push({ name: item.name, href: item.href });

      if (item?.modules?.length) {
        item.modules.forEach(subItem => searchSubmodules(subItem));
      }
      if (item?.nested?.length) {
        item.nested.forEach(subItem => searchSubmodules(subItem));
      }
    }

    // Es un moderador
    if (user.level_id === LEVEL.MODERATOR) {
      const userPermissions = user?.permissions?.map(x => x.permission_id);
      const filteredMenu = MENU_ADMIN.filter(item => {
        const isForAllUsers = !item.profile;
        const hasPermission = item.profile?.some(profile => userPermissions.includes(profile));
        return isForAllUsers || hasPermission;
      });

      // Obtetiene los enlaces del breadcrumb en base al menú asignado
      filteredMenu.forEach(item => searchSubmodules(item));
      setNavigation(filteredMenu);
      return setBreadcrumbs(breadcrumbs);
    }

    if (user.level_id === LEVEL.MARKETING) {
      const filteredMenu = MENU_MARKETING.filter(item => {
        const isForAllUsers = !item.profile;
        const hasPermission = item.profile?.includes(user.marketing_type);
        return isForAllUsers || hasPermission;
      });

      // Obtetiene los enlaces del breadcrumb en base al menú asignado
      filteredMenu.forEach(item => searchSubmodules(item));
      setNavigation(filteredMenu);
      return setBreadcrumbs(breadcrumbs);
    }

    // Es un administrador
    setNavigation(MENU_ADMIN);
    // Obtetiene los enlaces del breadcrumb en base al menú asignado
    MENU_ADMIN.forEach(item => searchSubmodules(item));
    setBreadcrumbs(breadcrumbs);
  }

  useEffect(() => {
    getUserMenu();
  }, [user]);

  return (
    <div id="layout">

      <Modal
        size="sm"
        backdrop="blur"
        isOpen={showModal}
        onClose={() => setShowModal(false)}
      >
        <Conversion />
      </Modal>

      <div className="desk-sidebar desk-sidebar__header">
        <div className="sidebar-logo">
          <div className="sidebar-logo__wrapper">
            <img className="sidebar-logo__cropped" src={CroppedLogo} alt={process.env.REACT_APP_NAME} />
            <img className="sidebar-logo__normal" src={Logo} alt={process.env.REACT_APP_NAME} />
          </div>
        </div>
        <div className="desk-headerbar">
          <button type="button" className="-mr-2.5 p-2.5 text-gray-700" onClick={toggleSidebar}>
            <span className="sr-only">Open sidebar</span>
            <IconMenu2 />
          </button>

          <div className="h-6 w-px bg-gray-200" aria-hidden="true" />

          <span className="hidden md:block font-medium text-lg">{ routeName }</span>

          <div className="flex flex-1 gap-x-4 self-stretch items-center lg:gap-x-6">
            <div className="relative flex flex-1" />

            <Button
              variant="bordered"
              disableAnimation
              onClick={() => user.level_id === LEVEL.ADMIN ? setShowModal(true) : null}
            >
              <span className="inline-flex items-center text-primary text-sm font-semibold">
                Bs. { conversion } = $ 1,00
              </span>
            </Button>

            <div className="flex items-center gap-x-4 lg:gap-x-6">
              <div className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-200" aria-hidden="true" />

              <div className="relative">
                <Dropdown placement="bottom-start">
                  <DropdownTrigger disabled={false}>
                    <Button variant="bordered">
                      <span className="font-semibold leading-6 text-gray-900" aria-hidden="true">
                        { user?.name }
                      </span>
                      <IconChevronDown className="ml-2 text-gray-400" size={18} />
                    </Button>
                  </DropdownTrigger>

                  <DropdownMenu aria-label="User Actions" variant="flat">
                    <DropdownItem onClick={() => nav('/perfil/autenticacion')}>
                      Cambiar contraseña
                    </DropdownItem>
                    <DropdownItem color="danger" onClick={() => nav('/logout')}>
                      Cerrar sesión
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
      </div>

      <SideBar navigation={navigation} />

      <main>
        {!!user && <Outlet />}
      </main>
    </div>
  )
}

const SideBar = ({ navigation }) => {
  return (
    <div className="desk-sidebar">
      <aside>
        <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 pb-4 transition-all duration-100">
          <nav className="flex flex-1 flex-col">
            <ul className="flex flex-1 flex-col -mx-2 space-y-1">
              {navigation.filter(x => !x.hidden).map((item, index) => (
                <MenuItem key={index} item={item} />
              ))}
            </ul>
          </nav>
        </div>
      </aside>
    </div>
  )
}

export const MenuItem = ({ item }) => {
  const location = useLocation();
  const [showItems, setShowItems] = useState(false);
  const isActive = location.pathname === item?.href;

  const openSidebar = () => {
    const items = document.getElementsByClassName('desk-sidebar');
    if (items.item(0).classList.contains('open')) return;

    for (let key = 0; key < items.length; key++) {
      if (!items.item(key).classList.contains('open')) {
        items.item(key).classList.toggle('open');
      }
    }
    setShowItems(true);
  }

  if (!item?.modules?.length) return (
    <li className="menu-item" title={item.name}>
      <Link
        to={item.href}
        className={classNames(
          isActive
            ? 'text-primary active'
            : 'text-gray-700 hover:text-primary',
          'group flex gap-x-2 rounded-md p-2 leading-6 font-semibold'
        )}
      >
        <item.icon className={classNames('aspect-square shrink-0', isActive ? 'text-primary':'text-gray-500 group-hover:text-primary')} />
        <span>{ item.name }</span>
      </Link>
    </li>
  )

  return (
    <li className="menu-item" title={item.name} onClick={openSidebar}>
      <Link
        to={item.modules[0].href}
        className={classNames(
          isActive
            ? 'text-primary active'
            : 'text-gray-700 hover:text-primary',
          'group flex items-center gap-x-3 rounded-md p-2 leading-6 font-semibold'
        )}
        onClick={() => setShowItems(!showItems)}
      >
        {!!item?.icon && (
          <item.icon className={classNames('aspect-square shrink-0', isActive ? 'text-primary':'text-gray-500 group-hover:text-primary')} />
        )}
        <span className="flex-1">{ item.name }</span>
        <IconChevronRight size={18} className={classNames('transition-all duration-100', showItems ? 'rotate-90':'rotate-0')} />
      </Link>

      {showItems && (
        <ul className="pl-9">
          {item?.modules?.map((subItem, subIdx) => {
            const isActive = location.pathname === subItem?.href;

            if (!subItem?.modules?.length) return (
              <li key={subIdx} className="menu-item" title={subItem.name}>
                <Link
                  to={subItem?.href}
                  className={classNames(
                    isActive
                      ? 'text-primary active'
                      : 'text-gray-700 hover:text-primary',
                    'group flex gap-x-3 rounded-md p-2 leading-6 font-semibold'
                  )}
                >
                  {!!subItem?.icon && (
                    <subItem.icon className={classNames('aspect-square shrink-0', isActive ? 'text-white':'text-gray-500 group-hover:text-primary')} />
                  )}
                  <span>{ subItem.name }</span>
                </Link>
              </li>
            )

            return (
              <MenuItem key={'sub' + subIdx} item={subItem} />
            )
          })}
        </ul>
      )}
    </li>
  )
}

export default Layout;