export const Constants = {
	PER_PAGE: 20,
	USER: {
		LEVELS: {
			ADMIN: 1,
			OWNER: 2,
			BENEFICIARY: 3,
			MODERATOR: 4,
			MARKETING: 5,
		},
		STATUS: {
			PENDING: 0,
			ACTIVE: 1,
			INACTIVE: 2,
		},
		VERIFIED: {
			PENDING: 0,
			APPROVED: 1,
			REJECTED: 2,
		},
		MARKETING_TYPE: {
			DISTRIBUTOR: 1,
			SELLER: 2,
			STORE: 3,
			INTERMEDIARY: 4,
		},
		REGISTERED_FROM: {
			APP: 1,
			WEB: 2,
		},
	},
	ORDER: {
		STATUS: {
			PENDING: 0,
			APPROVED: 1,
			REJECTED: 2,
		},
		MEDIC_STATUS: {
			PENDING: 0,
			APPROVED: 1,
			REJECTED: 2,
		},
	},
	ORDER_DOC: {
		FILENAME: {
			ORDER: 1,
			MEDICAL_REPORT: 2,
			DNI: 3,
			PRESCRIPTION: 4,
		}
	},
	PRODUCT: {
		STATUS: {
			INACTIVE: 0,
			ACTIVE: 1,
		},
	},
	PURCHASE: {
		STATUS: {
			PENDING: 0,
			APPROVED: 1,
			REJECTED: 2,
			CANCELLED: 3,
			ANALIZE: 4,
			NULLED: 5,
			APPROVED_ANALYSIS: 6,
		},
		TO_ANALIZE: {
			YES: 1,
			NO: 0,
		}
	},
	PAYMENT: {
		METHOD: {
			ADDRESS: 1,
			TRANSFER: 2,
			MOBILE: 3,
			CASH_USD: 4,
			ZELLE: 5,
			TRANSFER_MANUAL: 6,
		},
		STATUS: {
			PENDING: 0,
			APPROVED: 1,
			REJECTED: 2,
		},
	},
	TAXES: {
		IVA: 16,
		IGTF: 3,
	},
	PERMISSIONS: {
		SUBSCRIPTION: 1,
		SERVICES: 2,
		ADMINISTRATION: 3,
		ADMINISTRATOR: 4,
	},
}