import React, { useState } from 'react';
import { IconArrowLeft, IconArrowsMaximize, IconBriefcase, IconBuildingStore, IconUser } from '@tabler/icons-react';
import { Link, useLocation } from 'react-router-dom';
import { classNames, Constants } from '../../../../utils';
import { DistributorForm, IntermediaryForm, SellerForm, StoreForm } from './forms';

const TYPE = Constants.USER.MARKETING_TYPE;

const MarketingCreateUser = () => {
  const { pathname } = useLocation();
  const isNet = pathname.endsWith('/alterno');
  const [userType, setUserType] = useState(isNet ? TYPE.DISTRIBUTOR : TYPE.INTERMEDIARY);

  return (
    <>
      <Link to="/canales-comercializacion/intermediarios" className="flex gap-2 font-semibold cursor-pointer">
        <IconArrowLeft />
        <span>Volver</span>
      </Link>

      <div className="max-w-screen-lg mx-auto flex justify-center gap-4 my-4">
        {isNet ? (
          <>
            <TabItem
              label="Distribuidor"
              icon={<IconArrowsMaximize />}
              isActive={userType === TYPE.DISTRIBUTOR}
              onClick={() => setUserType(TYPE.DISTRIBUTOR, 'type')}
            />
            <TabItem
              label="Vendedor"
              icon={<IconUser />}
              isActive={userType === TYPE.SELLER}
              onClick={() => setUserType(TYPE.SELLER, 'type')}
            />
            <TabItem
              label="Comercio"
              icon={<IconBuildingStore />}
              isActive={userType === TYPE.STORE}
              onClick={() => setUserType(TYPE.STORE, 'type')}
            />
          </>
        ) : (
          <TabItem
            label="Intermediario"
            icon={<IconBriefcase />}
            isActive={userType === TYPE.INTERMEDIARY}
            onClick={() => setUserType(TYPE.INTERMEDIARY, 'type')}
          />
        )}
      </div>

      {userType === TYPE.DISTRIBUTOR && <DistributorForm />}
      {userType === TYPE.SELLER && <SellerForm />}
      {userType === TYPE.STORE && <StoreForm />}
      {userType === TYPE.INTERMEDIARY && <IntermediaryForm />}
    </>
  )
}

const TabItem = ({ icon, label, isActive, onClick }) => {
  return (
    <div
      onClick={onClick}
      className={classNames(
        'w-1/4 flex items-center gap-2 shadow-md p-4 rounded-xl cursor-pointer',
        isActive ? 'bg-[#3ACE76] text-white' : 'bg-white text-black'
      )}
    >
      <div className={classNames(isActive ? 'text-white' : 'text-primary')}>{ icon }</div>
      <span className="text-sm font-semibold">{ label }</span>
    </div>
  )
}

export default MarketingCreateUser;
