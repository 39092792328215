/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { IconArrowLeft } from '@tabler/icons-react';
import { Link, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { Spinner } from '@nextui-org/react';
import { Constants } from '../../../../utils';
import { MarketingService } from '../../../../services';
import { DistributorForm, IntermediaryForm, SellerForm, StoreForm } from './forms';

const TYPE = Constants.USER.MARKETING_TYPE;

const MarketingEditUser = () => {
  const { isLoading, user } = useForm();

  return (
    <>
      {isLoading && (
        <div className="w-screen h-screen fixed inset-0 z-[70] flex justify-center items-center bg-white/30">
          <Spinner />
        </div>
      )}

      <Link to="/canales-comercializacion/intermediarios" className="flex gap-2 font-semibold cursor-pointer">
        <IconArrowLeft />
        <span>Volver</span>
      </Link>

      {user?.marketing_type === TYPE.DISTRIBUTOR && <DistributorForm user={user} />}
      {user?.marketing_type === TYPE.SELLER && <SellerForm user={user} />}
      {user?.marketing_type === TYPE.STORE && <StoreForm user={user} />}
      {user?.marketing_type === TYPE.INTERMEDIARY && <IntermediaryForm user={user} />}
    </>
  )
}

const useForm = () => {
  const { id } = useParams();
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

	const getUser = async () => {
		const userId = atob(atob(id));
    if (isNaN(userId)) return toast.error('No se pudo encontrar el usuario');

    try {
      const user = await MarketingService.user.findOne(+userId);
      setUser(user);

    } catch (error) {
      toast.error(error.message);
    }
    setIsLoading(false);
	}

  useEffect(() => {
    getUser();
  }, []);

  return {
    isLoading,
    user,
  }
}

export default MarketingEditUser;
