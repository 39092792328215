import { useContext } from 'react';
import { Navigate, Route, RouterProvider, Routes, createHashRouter } from 'react-router-dom';
import { AuthContext } from '../context';
import Layout from './layout';
import { Constants } from '../utils';

// Auth
import Login from '../screens/auth/login';
import Logout from '../screens/auth/logout';
import RecoverPassword from '../screens/auth/recover-password';
import RecoverEmail from '../screens/auth/recover-email';

// ADMIN
import AdminProfile from '../screens/admin/profile/profile';
import AdminHome from '../screens/admin/home/home';
import AdminUsers from '../screens/admin/users/users';
import AdminModerators from '../screens/admin/moderators/moderators';
import AdminOrders from '../screens/admin/orders/orders';
import AdminServices from '../screens/admin/services/services';
import AdminPayments from '../screens/admin/payments/payments';
import AdminSubscriptions from '../screens/admin/subscriptions/subscriptions';
// Products
import AdminProducts from '../screens/admin/products/products';
import AdminProductCreate from '../screens/admin/products/create-product';
// Marketing
import AdminMarketingUsers from '../screens/admin/marketing/users/users';
import AdminMarketingCreateUser from '../screens/admin/marketing/users/create-user';
import AdminMarketingEditUser from '../screens/admin/marketing/users/edit-user';
import AdminMarketingCommissions from '../screens/admin/marketing/commissions/commissions';
import AdminMarketingSalesReport from '../screens/admin/marketing/reports/sales';
import AdminMarketingCommissionsReport from '../screens/admin/marketing/reports/commissions';

// MARKETING
import MarketingHome from '../screens/marketing/home/home';
import MarketingProfile from '../screens/marketing/profile/profile';
import MarketingProfilePassword from '../screens/marketing/profile/password';
import MarketingSalesReport from '../screens/marketing/reports/sales';
import MarketingCommissionsReport from '../screens/marketing/reports/commissions';

const Router = () => {
  const { isLogged, user, currentRouter } = useContext(AuthContext);

  const authRouter = createHashRouter([
    {
      index: true,
      path: '/',
      element: <Login />,
    },
    {
      path: '/recover',
      element: <RecoverEmail />,
    },
    {
      path: '/recover/:code',
      element: <RecoverPassword />,
    },
    {
      path: '*',
      element: <Navigate to="/" replace />,
    }
  ]);

  const adminRouter = createHashRouter([
    {
      path: '/logout',
      element: <Logout />,
    },
    {
      index: true,
      path: '*',
      Component: AdminRoutes,
    },
  ]);

  const marketingRouter = createHashRouter([
    {
      path: '/logout',
      element: <Logout />,
    },
    {
      index: true,
      path: '*',
      Component: MarketingRoutes,
    },
  ]);

  let router = authRouter;

  if (isLogged) {
    switch ((currentRouter || user?.level_id)) {
      case Constants.USER.LEVELS.ADMIN:
        router = adminRouter;
        break;

      case Constants.USER.LEVELS.MODERATOR:
        router = adminRouter;
        break;

      case Constants.USER.LEVELS.MARKETING:
        router = marketingRouter;
        break;

      default:
        router = authRouter;
        break;
    }
  }

  return (
    <RouterProvider router={router} />
  )
}

const AdminRoutes = () => (
  <Routes>
    <Route element={<Layout />}>
      <Route path="/" element={<AdminHome />} />
      <Route path="/productos" element={<AdminProducts />} />
      <Route path="/productos/nuevo" element={<AdminProductCreate />} />
      <Route path="/productos/:id/editar" element={<AdminProductCreate />} />
      <Route path="/moderadores" element={<AdminModerators />} />
      <Route path="/usuarios" element={<AdminUsers />} />
      <Route path="/solicitudes" element={<AdminOrders />} />
      <Route path="/servicios" element={<AdminServices />} />
      <Route path="/pagos" element={<AdminPayments />} />
      <Route path="/suscripciones" element={<AdminSubscriptions />} />
      <Route path="/perfil/autenticacion" element={<AdminProfile />} />

      <Route path="/canales-comercializacion/intermediarios" element={<AdminMarketingUsers />} />
      <Route path="/canales-comercializacion/intermediarios/nuevo/alterno" element={<AdminMarketingCreateUser />} />
      <Route path="/canales-comercializacion/intermediarios/nuevo" element={<AdminMarketingCreateUser />} />
      <Route path="/canales-comercializacion/intermediarios/:id/editar" element={<AdminMarketingEditUser />} />
      <Route path="/canales-comercializacion/comisiones" element={<AdminMarketingCommissions />} />
      <Route path="/canales-comercializacion/reportes/ventas" element={<AdminMarketingSalesReport />} />
      <Route path="/canales-comercializacion/reportes/comisiones" element={<AdminMarketingCommissionsReport />} />

      <Route path="*" element={<Navigate to="/" replace />} />
    </Route>
    <Route path="*" element={<Navigate to="/" replace />} />
  </Routes>
)

const MarketingRoutes = () => (
  <Routes>
    <Route element={<Layout />}>
      <Route path="/" element={<MarketingHome />} />
      <Route path="/perfil" element={<MarketingProfile />} />
      <Route path="/perfil/autenticacion" element={<MarketingProfilePassword />} />
      <Route path="/canales-comercializacion/reportes/ventas" element={<MarketingSalesReport />} />
      <Route path="/canales-comercializacion/reportes/comisiones" element={<MarketingCommissionsReport />} />

      <Route path="*" element={<Navigate to="/" replace />} />
    </Route>
    <Route path="*" element={<Navigate to="/" replace />} />
  </Routes>
)

export default Router;