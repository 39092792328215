/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Button, Spinner, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow } from '@nextui-org/react';
import toast from 'react-hot-toast';
import { SettingsService } from '../../../services/marketing';
import { clone } from '../../../utils';

const DistributorHome = () => {
  const { isLoading, onChange, products, submit } = useForm();
  const [tick, setTick] = useState(false);

  useEffect(() => {
    setTimeout(() => setTick(!tick), 10);
  }, [products]);

  return (
    <>
      {isLoading && (
        <div className="w-screen h-screen fixed inset-0 z-[70] flex justify-center items-center bg-white/30">
          <Spinner />
        </div>
      )}

      <div className="max-w-screen-lg mx-auto bg-white p-4 rounded-lg shadow-lg grid grid-cols-5 gap-4">
        <div className="col-span-2">
          <p className="text-lg text-primary font-bold mb-4">
            Comisiones por productos
          </p>

          <Table
            id="product-commissions-table"
            aria-label="Comisiones por productos"
            classNames={{ wrapper: 'p-0 border shadow-none border-0 rounded-none' }}
            isStriped
          >
            <TableHeader>
              <TableColumn>
                {/* <p className="invisible">Producto</p> */}
                <p>Producto</p>
              </TableColumn>
              <TableColumn>
                {/* <p className="invisible">Porcentaje</p> */}
                <p>Porcentaje</p>
              </TableColumn>
            </TableHeader>
            <TableBody items={products} emptyContent="No hay resultados">
              {(item) => (
                <TableRow key={item.id}>
                  <TableCell>{ item.product?.name }</TableCell>
                  <TableCell>{ item.commission_perc }</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>

        <div className="col-span-3">
          <p className="text-lg text-primary font-bold mb-4">
            Distribución por intermediario
          </p>

          <Table
            aria-label="Distribución por intermediario"
            classNames={{ wrapper: 'p-0 border shadow-none border-0 rounded-none' }}
            isStriped
          >
            <TableHeader>
              <TableColumn>Porcentaje aplicable a Distribuidor</TableColumn>
              {/* <TableColumn>Porcentaje aplicable a Vendedor</TableColumn>
              <TableColumn>Porcentaje aplicable a Comercios</TableColumn> */}
            </TableHeader>
            <TableBody>
              {products.map((item, index) => {
                const body = document.querySelector('#product-commissions-table tbody');
                const el = body?.getElementsByTagName('tr')?.item(index);
                const height = el?.getAttribute('data-key') ? el?.clientHeight : 'auto';

                return (
                  <TableRow key={index} style={{ height }}>
                    <TableCell>
                      <input
                        type="number"
                        step={0.01}
                        min={0}
                        className="outline-none cursor-pointer bg-transparent"
                        value={item?.commission_perc_distributor}
                        onChange={e => onChange(e.target.value, 'commission_perc_distributor', index)}
                        disabled
                      />
                    </TableCell>
                    {/* <TableCell>
                      <input
                        type="number"
                        step={0.01}
                        min={0}
                        className="outline-none cursor-pointer bg-transparent"
                        value={item?.commission_perc_seller}
                        onChange={e => onChange(e.target.value, 'commission_perc_seller', index)}
                      />
                    </TableCell>
                    <TableCell>
                      <input
                        type="number"
                        step={0.01}
                        min={0}
                        className="outline-none cursor-pointer bg-transparent"
                        value={item?.commission_perc_store}
                        onChange={e => onChange(e.target.value, 'commission_perc_store', index)}
                      />
                    </TableCell> */}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </div>

        {/* <div className="col-span-5 text-center">
          <Button color="primary" onClick={submit}>
            Editar
          </Button>
        </div> */}
      </div>
    </>
  );
}

const useForm = () => {
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

	const getProducts = async () => {
    try {
      const user = await SettingsService.findAllProducts();
      setProducts(user);

    } catch (error) {
      toast.error(error.message);
    }
    setIsLoading(false);
	}

  const onChange = (value, field, index) => {
    setProducts(s => {
      const products = clone(s);
      products[index][field] = value;
      return products;
    });
  }

  const isValidForm = () => {
    const onError = (msg) => {
      toast.error(msg);
      return false;
    }

    const hasEmptyPercentage = products.some(x => {
      return x.commission_perc_distributor === '' || x.commission_perc_seller === '' || x.commission_perc_store === '';
    });
    if (hasEmptyPercentage)
      return onError('Debe indicar todos los porcentajes');

    const hasNegativePercentage = products.some(x => {
      return +x.commission_perc_distributor < 0 || +x.commission_perc_seller < 0 || +x.commission_perc_store < 0;
    });
    if (hasNegativePercentage)
      return onError('Los porcentajes deben ser números positivos');

    const hasOverPercentage = products.some(x => {
      const total = +x.commission_perc_distributor + +x.commission_perc_seller + +x.commission_perc_store;
      return total > x.commission_perc;
    })
    if (hasOverPercentage)
      return onError('La distribución no puede superar el porcentaje total');

    const hasSubPercentage = products.some(x => {
      const total = +x.commission_perc_distributor + +x.commission_perc_seller + +x.commission_perc_store;
      return total < x.commission_perc;
    })
    if (hasSubPercentage)
      return onError('La distribución debe ser igual al porcentaje total');

    return true;
  }

  const submit = async () => {
    if (!isValidForm()) return;

    setIsLoading(true);
    try {
      const data = {
        products: products.map(x => ({
          product_id: x.product_id,
          commission_perc_distributor: x.commission_perc_distributor,
          commission_perc_seller: x.commission_perc_seller,
          commission_perc_store: x.commission_perc_store,
        })),
      };

      await SettingsService.updateProducts(data);
      toast.success('Productos actualizados');

    } catch (error) {
      toast.error(error);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    getProducts();
  }, []);

  return {
    isLoading,
    onChange,
    products,
    submit,
  }
}

export default DistributorHome;
