/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Button, Divider, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Pagination, Spinner, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow } from '@nextui-org/react';
import { IconSearch } from '@tabler/icons-react';
import toast from 'react-hot-toast';
import moment from 'moment';
import { Constants, formatAmount, fromStorage, sanitizeToNumbers } from '../../../../utils';
import { MarketingService } from '../../../../services';

const MODAL_ACTION = {
  NONE: 0,
  VIEW: 1,
}

const MarketingCommissions = () => {
  const [selectedItem, setSelectedItem] = useState();
  const [modalAction, setModalAction] = useState(MODAL_ACTION.NONE);
  const { canResetFilter, data, filterBy, goToPage, isLoading, pagination, print, reload } = useFetchTable();

  const onSelectItem = (item, action) => {
    setSelectedItem(item);
    setModalAction(action);
  }

  const closeModal = (reloading = false) => {
    setSelectedItem(null);
    setModalAction(MODAL_ACTION.NONE);
    if (reloading) reload();
  }

  return (
    <>
      {isLoading && (
        <div className="w-screen h-screen fixed inset-0 z-[70] flex justify-center items-center bg-white/30">
          <Spinner />
        </div>
      )}

      <Modal
        size="2xl"
        isOpen={modalAction === MODAL_ACTION.VIEW}
        onClose={() => closeModal()}
        backdrop="blur"
        scrollBehavior="outside"
      >
        <ModalContent>
          <ModalView item={selectedItem} />
        </ModalContent>
      </Modal>

      <Filters
        canResetFilter={canResetFilter}
        filterBy={filterBy}
        resetFilter={() => reload()}
      />

      <Table aria-label="Registro de comisiones"
        topContent={
          <div className="flex flex-row justify-between items-center gap-4">
            <h3 className="text-xl font-medium text-primary">Registro de comisiones</h3>

            <div className="flex gap-2">
              <Button variant="bordered" onClick={print}>
                Exportar
              </Button>
            </div>
          </div>
        }
        topContentPlacement="inside"
      >
        <TableHeader>
          <TableColumn>N° de Venta</TableColumn>
          <TableColumn>Fecha de Venta</TableColumn>
          <TableColumn>Nombre del Titular</TableColumn>
          <TableColumn>Código de Intermediario</TableColumn>
          <TableColumn>Producto o Servicio Vendido</TableColumn>
          <TableColumn>Monto de Venta $</TableColumn>
          <TableColumn>Comisiones Generadas $</TableColumn>
        </TableHeader>
        <TableBody items={data} emptyContent="No hay resultados">
          {(item) => {
            const amount = item?.commissions[0]?.purchase_product?.price * item?.commissions[0]?.payment?.fees_qty;
            return (
              <TableRow key={item.id}>
                <TableCell>{ String(item.id).padStart(4,'0') }</TableCell>
                <TableCell>{ moment(item.created_at).format('DD/MM/YYYY') }</TableCell>
                <TableCell>{ item?.commissions[0]?.payment?.user?.fullName }</TableCell>
                <TableCell>{ item?.commissions[0]?.payment?.user?.referral_user?.intermediary_code }</TableCell>
                <TableCell>{ item?.commissions[0]?.product?.name }</TableCell>
                <TableCell>{ formatAmount(amount) }</TableCell>
                <TableCell>
                  <span
                    className="cursor-pointer text-primary font-semibold"
                    onClick={() => onSelectItem(item, MODAL_ACTION.VIEW)}
                  >
                    Ver
                  </span>
                </TableCell>
              </TableRow>
            )
          }}
        </TableBody>
      </Table>

      <div className="flex w-full justify-center mt-4">
        <Pagination
          showControls
          variant="bordered"
          page={pagination.page}
          total={pagination.pages}
          onChange={goToPage}
        />
      </div>
    </>
  )
}

const Filters = ({ canResetFilter, filterBy, resetFilter }) => {
  const initialFilter = {
    payment_id: '',
    search: '',
    code: '',
    search_product: '',
    since: '',
    until: '',
  };
  const [form, setForm] = useState(initialFilter);

  const onChange = (value, target) => {
    setForm(s => ({ ...s, [target]: value }));
    filterBy(value, target);
  }

  return (
    <section className="mb-4 grid sm:flex grid-cols-2 lg:flex-row lg:flex-wrap items-end gap-4">
      <Input
        classNames={{
          base: 'w-full sm:max-w-[12rem]',
          inputWrapper: 'border-1 h-10',
        }}
        label="N° de venta"
        labelPlacement="outside"
        placeholder=" "
        variant="bordered"
        value={form.payment_id}
        onValueChange={v => onChange(sanitizeToNumbers(v), 'payment_id')}
      />
      <Input
        classNames={{
          base: 'w-full sm:max-w-[12rem]',
          inputWrapper: 'border-1 h-10',
        }}
        label="Buscar"
        labelPlacement="outside"
        placeholder="Nombre del titular"
        startContent={<IconSearch />}
        variant="bordered"
        value={form.search}
        onValueChange={v => onChange(v, 'search')}
      />
      <Input
        classNames={{
          base: 'w-full sm:max-w-[15rem]',
          inputWrapper: 'border-1 h-10',
        }}
        label="Buscar"
        labelPlacement="outside"
        placeholder="Código de intermediario"
        startContent={<IconSearch />}
        variant="bordered"
        value={form.code}
        onValueChange={v => onChange(v, 'code')}
      />
      <Input
        classNames={{
          base: 'w-full sm:max-w-[15rem]',
          inputWrapper: 'border-1 h-10',
        }}
        label="Buscar"
        labelPlacement="outside"
        placeholder="Producto o servicio"
        startContent={<IconSearch />}
        variant="bordered"
        value={form.search_product}
        onValueChange={v => onChange(v, 'search_product')}
      />
      <Input
        type="date"
        classNames={{
          base: 'w-full sm:max-w-[10rem]',
          inputWrapper: 'border-1 h-10',
          input: `pr-0 text-${!!form.since ? '[]':'foreground-400'}`,
        }}
        label="Desde"
        labelPlacement="outside"
        placeholder=" "
        variant="bordered"
        value={form.since}
        onValueChange={v => onChange(v, 'since')}
      />
      <Input
        type="date"
        classNames={{
          base: 'w-full md:max-w-[10rem]',
          inputWrapper: 'border-1 h-10',
          input: `pr-0 text-${!!form.until ? '[]':'foreground-400'}`,
        }}
        label="Hasta"
        labelPlacement="outside"
        placeholder=" "
        variant="bordered"
        value={form.until}
        onValueChange={v => onChange(v, 'until')}
      />
      {canResetFilter && (
        <Button
          variant="light"
          className="text-primaryDark"
          onClick={() => {
            setForm(initialFilter);
            resetFilter();
          }}
        >
          Limpiar filtros
        </Button>
      )}
    </section>
  )
}

const ModalView = ({ item }) => {
  const onPrint = () => {
    const printContent = document.getElementById('printable-content');
    const printArea = document.getElementById('print-area');
    printArea.innerHTML = printContent.innerHTML;
    window.print();
    printArea.innerHTML = '';
  }

  const unique_products = [];
  item?.commissions?.forEach(comm => {
    if (unique_products.some(x => x.purchase_product_id === comm.purchase_product_id)) return;

    unique_products.push({
      ...comm.product,
      purchase_product_id: comm.purchase_product_id,
      fees: comm?.purchase_product?.fees,
      commissions: item?.commissions?.filter(c => c.purchase_product_id === comm.purchase_product_id) || [],
    });
  });

  return (
    <>
      <ModalHeader className="flex flex-col gap-1 text-primary">Comisiones Generadas $</ModalHeader>
      <ModalBody>
        <div id="printable-content">
          <div className="flex justify-between font-semibold text-sm text-black">
            <span>{ item?.commissions[0]?.payment?.user?.referral_user?.marketing_type_text }</span>
            <span>N° de Venta</span>
          </div>
          <div className="flex justify-between">
            <p className="font-semibold">
              <span>{ item?.commissions[0]?.payment?.user?.referral_user?.name }</span>
              <span> - </span>
              <span className="text-primary">
                Código { item?.commissions[0]?.payment?.user?.referral_user?.intermediary_code }
              </span>
            </p>
            <span className="font-bold text-primary">
              { String(item.id).padStart(4,'0') }
            </span>
          </div>

          <Divider className="my-4" />

          {unique_products?.map((prod, idx) => (
            <Fragment key={idx}>
              <div className="flex justify-between font-bold text-primary mb-2">
                <p>{ prod.name }</p>
                <p>
                  <span>Frecuencia de pago: </span>
                  <span className="text-gray-600">{ prod.fees > 1 ? 'Por cuotas' : 'Anual' }</span>
                </p>
              </div>

              <Table
                key={idx}
                aria-label="Comisiones Generadas"
                classNames={{
                  wrapper: 'p-0 border shadow-none border-0 border-b rounded-none mb-4'
                }}
              >
                <TableHeader>
                  <TableColumn>Nombre</TableColumn>
                  <TableColumn>Tipo de Canal</TableColumn>
                  <TableColumn>Código del Intermediario</TableColumn>
                  <TableColumn>Porcentaje %</TableColumn>
                  <TableColumn>Monto de Comisión $</TableColumn>
                </TableHeader>
                <TableBody items={prod.commissions} emptyContent="No hay resultados">
                  {(comm) => (
                    <TableRow key={comm.id}>
                      <TableCell>{ comm?.user?.name }</TableCell>
                      <TableCell>{ comm?.user?.marketing_type_text }</TableCell>
                      <TableCell>{ comm?.user?.intermediary_code }</TableCell>
                      <TableCell>{ comm?.percentage }</TableCell>
                      <TableCell>{ formatAmount(comm?.amount) }</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Fragment>
          ))}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button className="font-semibold" onPress={onPrint} color="primary">
          <span>Imprimir</span>
        </Button>
      </ModalFooter>
    </>
  )
}

const useFetchTable = () => {
  const initialFilters = {
    page: 1,
    perPage: Constants.PER_PAGE,
    payment_id: '',
    search: '',
    code: '',
    search_product: '',
    since: null,
    until: null,
  };

  const initialPagination = {
    page: 1,
    pages: 1,
    total: 0,
    perPage: Constants.PER_PAGE,
  };

  const [data, setData] = useState([]);
  const [canFetch, setCanFetch] = useState(true);
  const [filters, setFilters] = useState(initialFilters);
  const [pagination, setPagination] = useState(initialPagination);

  const debounceTime = 500;
  const debounce = useRef();

  const fetchData = async () => {
    if (!canFetch) return;
    setCanFetch(false);

    try {
      const response = await MarketingService.commissions.findAll(filters);
      const { data, ...rest } = response;

      setData(data);
      setPagination(rest);
      setCanFetch(true);

    } catch (error) {
      setData([]);
      onError(String(error));
    }
  }

  const print = async () => {
    setCanFetch(false);
    try {
      const res = await MarketingService.commissions.download(filters);
      const el = document.createElement('a');
      el.href = fromStorage(res.url);
      el.download = 'Gestión de Comisiones';
      el.target = '_blank';
      el.click();
      el.remove();

    } catch (error) {
      onError(String(error));
    }
    setCanFetch(true);
  }

  const getCurrentPagination = () => {
    // Truco para obtener el estado actualizado (pagination es mantenida con el estado actual por el componente Pagination)
    let pag;
    setPagination(s => {
      pag = s;
      return s;
    });
    return pag;
  }

  const canResetFilter = () => {
    const { page, perPage, ...initial } = initialFilters;
    const { page: _, perPage: __, ...current } = filters;
    const initFilter = JSON.stringify(initial);
    const currFilter = JSON.stringify(current);
    return initFilter !== currFilter;
  }

  const onError = (msg) => toast.error(msg);

  const reload = (inSamePage = false) => {
    setCanFetch(true);
    if (!inSamePage) setFilters(initialFilters);
    else fetchData();
  }

  const goToPage = (page) => {
    const pagination = getCurrentPagination();
    if (page >= 1 && page <= pagination.pages && page !== pagination.page) {
      setCanFetch(true);
      setFilters({ ...filters, page });
    }
  }

  const changePerPage = (perPage) => {
    setCanFetch(true);
    setFilters({ ...filters, perPage });
  }

  const filterBy = (value, target) => {
    if (debounce.current) clearTimeout(debounce.current);
    debounce.current = setTimeout(() => {
      setCanFetch(true);
      setFilters({ ...filters, page: 1, [target]: value });
    }, debounceTime);
  }

  useEffect(() => {
    fetchData();
  }, [filters]);

  return {
    canResetFilter: canResetFilter(),
    changePerPage,
    data,
    filterBy,
    filters,
    goToPage,
    isLoading: !canFetch,
    pagination,
    print,
    reload,
  }
}

export default MarketingCommissions;