/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from 'react';
import { Button, Divider, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Spinner } from '@nextui-org/react';
import { IconArrowsMaximize, IconBuildingStore, IconUser } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { classNames, Constants } from '../../../../utils';
import { MarketingService } from '../../../../services';

const TYPE = Constants.USER.MARKETING_TYPE;

const ModalViewNet = ({ item }) => {
  const { distributor, isLoading } = useFetch(item.id);
  const [ selectedItem, setSelectedItem ] = useState();

  const Label = ({ icon, label }) => (
    <div className="flex items-center text-sm font-semibold gap-2 mb-2">
      <div className="flex justify-center items-center w-8 h-8 rounded-full bg-gray-200 text-primary">
        { icon }
      </div>
      <p>{ label }</p>
    </div>
  );

  const NetItem = ({ isSelected, user }) => {
    let location = 1;
    if (user?.marketing_type === TYPE.SELLER) location = 2;
    if (user?.marketing_type === TYPE.STORE) location = 3;

    return (
      <div
        className={classNames(
          'w-full h-16 rounded-xl flex items-center p-2 border cursor-pointer',
          isSelected ? 'bg-primary text-white border-primary' : 'bg-white text-black border-gray-400'
        )}
        style={{ gridColumnStart: location, gridColumnEnd: location + 1 }}
        onClick={() => setSelectedItem(user)}
      >
        <p>{ user?.name }</p>
      </div>
    );
  }

  return (
    <>
      <Modal size="lg" isOpen={!!selectedItem} onClose={() => setSelectedItem(null)} scrollBehavior="outside">
        <ModalDetails user={selectedItem} />
      </Modal>

      <ModalHeader className="flex flex-col gap-1">Ubicación en la Red</ModalHeader>
      <ModalBody>
        {isLoading ? (
          <div className="flex justify-center items-center bg-white/30">
            <Spinner />
          </div>
        ) : (
          <div className="w-full grid grid-cols-3 gap-2 pb-4">
            <Label
              icon={<IconArrowsMaximize size={18} />}
              label="Distribuidor"
            />
            <Label
              icon={<IconUser size={18} />}
              label="Vendedor"
            />
            <Label
              icon={<IconBuildingStore size={18} />}
              label="Comercio"
            />

            <NetItem
              user={distributor}
              isSelected={distributor?.id === item.id}
            />

            {distributor?.sellers.map((seller, sellerIdx) => (
              <Fragment key={sellerIdx}>
                <NetItem
                  user={seller}
                  isSelected={seller.id === item.id}
                />
                {seller.stores.map((store, storeIdx) => (
                  <NetItem
                    key={storeIdx}
                    user={store}
                    isSelected={store.id === item.id}
                  />
                ))}
                <Divider className="my-2 col-span-3" />
              </Fragment>
            ))}
          </div>
        )}
      </ModalBody>
    </>
  )
}

const ModalDetails = ({ user }) => {
  const nav = useNavigate();

  const onEdit = () => {
    const userId = btoa(btoa(String(user?.id).padStart(5,'0')));
    nav(`/canales-comercializacion/intermediarios/${userId}/editar`);
  }

  return (
    <ModalContent>
      <ModalHeader className="flex flex-col gap-1 text-primary">
        { user?.marketing_type_text }
      </ModalHeader>
      <ModalBody>
        <div className="[&>p>span]:font-semibold">
          <p><span>Nombre</span>: { user?.name }</p>
          <p><span>Cédula de identidad</span>: { user?.dni }</p>
          <p><span>Teléfono móvil</span>: { user?.phone }</p>
          {!!user?.birthdate && (
            <p><span>Fecha de nacimiento</span>: { moment(user?.birthdate).format('DD/MM/YYYY') }</p>
          )}
          <p><span>Correo electrónico</span>: { user?.email }</p>
          <p><span>Actividad económica</span>: { user?.economic_activity ?? '--' }</p>
          <p><span>Dirección</span>: { user?.address ?? '--' }</p>
          <p><span>Estado</span>: { user?.state?.name ?? '--' }</p>
          <p><span>Municipio</span>: { user?.township?.name ?? '--' }</p>
          <p><span>Parroquia</span>: { user?.parish?.name ?? '--' }</p>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          className="border-primary text-primary font-semibold"
          variant="bordered"
          onPress={onEdit}
        >
          Editar
        </Button>
      </ModalFooter>
    </ModalContent>
  )
}

const useFetch = (userId) => {
  const [distributor, setDistributor] = useState();
  const [user, setUser] = useState();
  const [users, setUsers] = useState([]);
  const [isLoading, setisLoading] = useState(false);

  const fetchData = async () => {
    setisLoading(true);
    try {
      const res = await MarketingService.user.findUserNet(userId);

      const distributor = res.users.find(x => x.marketing_type === TYPE.DISTRIBUTOR);
      distributor.sellers = res.users.filter(x => x.marketing_type === TYPE.SELLER);
      distributor.sellers.forEach(seller => {
        seller.stores = res.users.filter(x => x.marketing_type === TYPE.STORE && x.marketing_seller_id === seller.id);
      });

      setDistributor(distributor);
      setUser(res.user);
      setUsers(res.users);

    } catch (error) {
      console.log(error);
    }
    setisLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, [userId]);

  return {
    distributor,
    isLoading,
    user,
    users,
  }
}

export default ModalViewNet;