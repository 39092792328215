import { fetchWrapper, objectToQueryParams } from '../utils';

export class user {
  static baseUrl = process.env.REACT_APP_BASE_API + 'admin/marketing/users';

  static async create(form) {
    return await fetchWrapper.post(this.baseUrl, form);
  }

  static async findOne(id) {
    const url = `${this.baseUrl}/${id}`;
    return await fetchWrapper.get(url);
  }

  static async findAll(filters) {
    const url = `${this.baseUrl}?${objectToQueryParams(filters)}`;
    return await fetchWrapper.get(url);
  }

  static async findAllForSelect(filters) {
    const url = `${this.baseUrl}/for-select?${objectToQueryParams(filters)}`;
    return await fetchWrapper.get(url);
  }

  static async findUserNet(id) {
    const url = `${this.baseUrl}/${id}/net`;
    return await fetchWrapper.get(url);
  }

  static async update(form) {
    return await fetchWrapper.patch(this.baseUrl, form);
  }

  static async destroy(id) {
    return await fetchWrapper.delete(this.baseUrl, { id });
  }

  static async download(filters) {
    const url = `${this.baseUrl}/download?${objectToQueryParams(filters)}`;
    return await fetchWrapper.get(url);
  }
}

export class commissions {
  static baseUrl = process.env.REACT_APP_BASE_API + 'admin/marketing/commissions';

  static async findAll(filters) {
    const url = `${this.baseUrl}?${objectToQueryParams(filters)}`;
    return await fetchWrapper.get(url);
  }

  static async download(filters) {
    const url = `${this.baseUrl}/download?${objectToQueryParams(filters)}`;
    return await fetchWrapper.get(url);
  }
}

export class reports {
  static baseUrl = process.env.REACT_APP_BASE_API + 'admin/marketing/reports';

  static async findAllCommissions(filters) {
    const url = `${this.baseUrl}/commissions?${objectToQueryParams(filters)}`;
    return await fetchWrapper.get(url);
  }

  static async downloadAllCommissions(filters) {
    const url = `${this.baseUrl}/commissions/download?${objectToQueryParams(filters)}`;
    return await fetchWrapper.get(url);
  }

  static async findAllSales(filters) {
    const url = `${this.baseUrl}/sales?${objectToQueryParams(filters)}`;
    return await fetchWrapper.get(url);
  }

  static async downloadAllSales(filters) {
    const url = `${this.baseUrl}/sales/download?${objectToQueryParams(filters)}`;
    return await fetchWrapper.get(url);
  }
}