import React, { useContext } from 'react';
import { Constants } from '../../../utils';
import { AuthContext } from '../../../context';
import DistributorHome from './distributor-home';
import SellerHome from './seller-home';
import StoreHome from './store-home';
import IntermediaryHome from './intermediary-home';

const Home = () => {
  const { user } = useContext(AuthContext);

  return (
    <>
      {user?.marketing_type === Constants.USER.MARKETING_TYPE.DISTRIBUTOR && (
        <DistributorHome />
      )}
      {user?.marketing_type === Constants.USER.MARKETING_TYPE.SELLER && (
        <SellerHome />
      )}
      {user?.marketing_type === Constants.USER.MARKETING_TYPE.STORE && (
        <StoreHome />
      )}
      {user?.marketing_type === Constants.USER.MARKETING_TYPE.INTERMEDIARY && (
        <IntermediaryHome />
      )}
    </>
  );
}

export default Home;
