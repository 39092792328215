import React from 'react';
import { IconArrowsMaximize, IconQrcode } from '@tabler/icons-react';
import { classNames } from '../utils';

const QRImage = ({ id, image, name, showExpand = false }) => {
  const download = () => {
    const link = document.createElement('a');
    link.href = image;
    link.download = true;
    link.target = '_blank';
    link.click();
    link.remove();
  }

  const show = async () => {
    if (!image) return;

    try {
      let url = image;

      if (!String(image).startsWith('http')) {
        const res = await fetch(image);
        const blob = await res.blob();
        url = URL.createObjectURL(blob);
      }

      const link = document.createElement('a');
      link.href = url;
      link.target = '_blank';
      link.click();
      link.remove();

    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div>
      <div className="flex justify-between items-center gap-4 mb-4">
        <div className="flex items-center gap-2">
        </div>

        {showExpand && (
          <IconArrowsMaximize className="text-primary cursor-pointer unprintable" onClick={download} />
        )}
      </div>

      <div id={id} className={classNames('bg-gray-200 print:bg-transparent p-4 rounded-lg', name ? 'pb-0' : '')}>
        <div className="bg-white w-max aspect-square flex justify-center items-center">
          {image ? (
            <img src={image} className="w-48 h-48 object-contain" alt="QR" onClick={show} />
          ) : (
            <IconQrcode size={192} className="text-gray-200" />
          )}
        </div>

        {!!name && (
          <p className="text-center font-bold text-primary break-words">{ name }</p>
        )}
      </div>
    </div>
  )
}

export default QRImage;