import React, { useState } from 'react';
import { Button, Input } from '@nextui-org/react';
import toast from 'react-hot-toast';
import { AuthService } from '../../services';
import { Logo } from '../../assets/images';
import { useNavigate } from 'react-router-dom';

const RecoverEmail = () => {
  const nav = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState({ email: '' });

  const onChange = (value, target) => {
    setForm(s => ({ ...s, [target]: value }))
  }

  const isValidForm = () => {
    const onError = (msg) => {
      toast.error(msg);
      return false;
    }

		if (!form.email )
			return onError('El correo es obligatorio');

		if (!String(form.email).match(/^[a-z0-9._-]+@[a-z0-9._-]+\.[a-z]{2,3}$/ig))
			return onError('El correo no es válido');

    return true;
  }

  const onSubmit = async (event) => {
    event.preventDefault();
    if (!isValidForm()) return;
    setIsLoading(true);

    try {
      await AuthService.recover(form);
      toast.success('Se le ha enviado un correo electrónico para la recuperación de contraseña');
      nav('/');

    } catch (error) {
      toast.error(error);
    }
    setIsLoading(false);
  }

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img
          className="mx-auto h-32 w-auto"
          src={Logo}
          alt={process.env.REACT_APP_NAME}
        />
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Recuperar contraseña
        </h2>

        <p className="text-sm">Ingrese su correo electrónico y recibirá un código de acceso</p>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-sm">
        <form className="space-y-6" onSubmit={onSubmit}>
          <div className="text-sm font-medium leading-6 flex flex-col gap-4">
            <Input
              label="Correo"
              labelPlacement="outside"
              placeholder="Ingresa tu correo"
              variant="bordered"
              radius="sm"
              value={form.email}
              onValueChange={v => onChange(v, 'email')}
            />
          </div>

          <Button type="submit" color="primary" radius="sm" fullWidth isLoading={isLoading} onClick={onSubmit}>
            Enviar
          </Button>
        </form>
      </div>
    </div>
  )
}

export default RecoverEmail;