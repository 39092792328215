import { fetchWrapper } from '../../utils';

const baseUrl = process.env.REACT_APP_BASE_API + 'marketing/general';

export const findDistributor = async (id) => {
  const url = `${baseUrl}/distributors/${id}`;
  return await fetchWrapper.get(url);
}

export const findSeller = async (id) => {
  const url = `${baseUrl}/sellers/${id}`;
  return await fetchWrapper.get(url);
}