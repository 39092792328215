import React from 'react';
import { Button, Input, Select, SelectItem } from '@nextui-org/react';
import { Constants } from '../../../../utils';
import { useForm } from '.';

const DistibutorStore = ({ user }) => {
  const { form, isLoading, onChange, onSubmit, parishes, states, townships } = useForm(Constants.USER.MARKETING_TYPE.DISTRIBUTOR, user);

  return (
    <div className="max-w-screen-lg mx-auto flex flex-col bg-white p-4 rounded-lg">
      <h1 className="text-primary text-2xl font-bold text-center mb-8">
        Perfil de Usuario Distribuidor
      </h1>

      <div className="grid grid-cols-3 gap-4 items-start text-sm">
        <div>
          <p className="font-semibold">Nombre del distribuidor</p>
          <p>{ form.name }</p>
        </div>
        <div>
          <p className="font-semibold">N° de Documento (RIF)</p>
          <p>{ form.dni }</p>
        </div>
        <div>
          <p className="font-semibold">Teléfono Fijo del distribuidor (opcional)</p>
          <p>{ form.phone_secondary }</p>
        </div>
        <div>
          <p className="font-semibold">Teléfono Móvil del distribuidor (opcional)</p>
          <p>{ form.phone }</p>
        </div>
        <div>
          <p className="font-semibold">Correo de contacto</p>
          <p>{ form.email }</p>
        </div>
        <div>
          <p className="font-semibold">Actividad económica (Ramo)</p>
          <p>{ form.economic_activity }</p>
        </div>
        <div>
          <p className="font-semibold">Código de intermediario (opcional)</p>
          <p>{ form.intermediary_code }</p>
        </div>

        <div className="col-span-3">
          <Input
            label="Dirección"
            labelPlacement="outside"
            placeholder=" "
            variant="bordered"
            classNames={{ inputWrapper: 'border-1', label: 'font-semibold' }}
            value={form.address}
            onValueChange={v => onChange(v, 'address')}
          />
        </div>

        <Select
          label="Estado"
          labelPlacement="outside"
          variant="bordered"
          placeholder=" "
          classNames={{ trigger: 'border-1', label: 'font-semibold' }}
          disallowEmptySelection={true}
          selectedKeys={!!states.length && !!form.state_id ? [form.state_id] : []}
          onSelectionChange={v => onChange(v.currentKey, 'state_id')}
        >
          {states.map((item) => (
            <SelectItem key={item.value} value={item.value}>
              { item.label }
            </SelectItem>
          ))}
        </Select>
        <Select
          label="Municipio"
          labelPlacement="outside"
          variant="bordered"
          placeholder=" "
          classNames={{ trigger: 'border-1', label: 'font-semibold' }}
          disallowEmptySelection={true}
          selectedKeys={!!townships.length && !!form.township_id ? [form.township_id] : []}
          onSelectionChange={v => onChange(v.currentKey, 'township_id')}
        >
          {townships.map((item) => (
            <SelectItem key={item.value} value={item.value}>
              { item.label }
            </SelectItem>
          ))}
        </Select>
        <Select
          label="Parroquia"
          labelPlacement="outside"
          variant="bordered"
          placeholder=" "
          classNames={{ trigger: 'border-1', label: 'font-semibold' }}
          disallowEmptySelection={true}
          selectedKeys={!!parishes.length && !!form.parish_id ? [form.parish_id] : []}
          onSelectionChange={v => onChange(v.currentKey, 'parish_id')}
        >
          {parishes.map((item) => (
            <SelectItem key={item.value} value={item.value}>
              { item.label }
            </SelectItem>
          ))}
        </Select>
      </div>

      <div className="mt-8 mb-4 flex justify-center">
        <Button
          isLoading={isLoading}
          disabled={isLoading}
          color="primary"
          onPress={onSubmit}
        >
          Guardar
        </Button>
      </div>
    </div>
  )
}

export default DistibutorStore;
