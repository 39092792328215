/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { AuthService, GeneralService } from '../../../../services';
import { GeneralService as MarketingGeneralService } from '../../../../services/marketing';
import { Constants } from '../../../../utils';
import moment from 'moment';
import { AuthContext } from '../../../../context';

const TYPE = Constants.USER.MARKETING_TYPE;

export const useForm = (type, user) => {
  const dniArray = user?.dni ? String(user.dni).split('') : '';
  if (dniArray) dniArray.splice(1,0,'-');

  const initialForm = {
    id:  user?.id ?? '',
    marketing_type: type,
    name: user?.name ?? '',
    dni: user?.dni ? dniArray?.join('') : '',
    phone: user?.phone || '',
    phone_secondary: user?.phone_secondary || '',
    birthdate: user.birthdate ? moment(user?.birthdate).format('DD/MM/YYYY') : null,
    email: user?.email ?? '',
    address: user?.address ?? '',
    state_id: user?.state_id ? String(user?.state_id) : null,
    township_id: user?.township_id ? String(user?.township_id) : null,
    parish_id: user?.parish_id ? String(user?.parish_id) : null,
    economic_activity: user?.economic_activity ?? '',
    intermediary_code: user?.intermediary_code ?? '',
  };

  const { login } = useContext(AuthContext);
  const [isLoading, setisLoading] = useState(false);
  const [form, setForm] = useState(initialForm);
  const [states, setStates] = useState([]);
  const [townships, setTownships] = useState([]);
  const [parishes, setParishes] = useState([]);
  const [distributor, setDistributor] = useState(null);
  const [seller, setSeller] = useState(null);

  const getSelects = async () => {
    Promise.all([
      GeneralService.getStates(),
    ])
      .then(res => {
        setStates(res[0].map(x => ({ value: x.id, label: x.name })) ?? []);
      })
      .catch(console.log);
  }

  const getTownships = async (state_id) => {
    if (!state_id) return;
    GeneralService.getTownships({ state_id })
      .then(res => {
        setTownships(res.map(x => ({ value: x.id, label: x.name })));
      })
      .catch(console.log);
  }

  const getParishes = async (township_id) => {
    if (!township_id) return;
    GeneralService.getParishes({ township_id })
      .then(res => {
        setParishes(res.map(x => ({ value: x.id, label: x.name })));
      })
      .catch(console.log);
  }

  const getDistributor = async (id) => {
    MarketingGeneralService.findDistributor(id)
      .then(res => setDistributor(res))
      .catch(console.log);
  }

  const getSeller = async (id) => {
    MarketingGeneralService.findSeller(id)
      .then(res => setSeller(res))
      .catch(console.log);
  }

  const onChange = (value, target) => {
    // Ejecuta el cambio en el formulario
    setForm(s => ({ ...s, [target]: value }));

    // Ejecuta los cambios dependientes del valor/campo seleccionado
    if (target === 'state_id') {
      if (value) getTownships(value);
      onChange(null, 'township_id');
    }
    if (target === 'township_id') {
      if (value) getParishes(value);
      onChange(null, 'parish_id');
    }
  }

  const onError = (msg) => {
    toast.error(msg);
    return false;
  }

  const isValidForm = () => {
    // Validaciones por tipo de usuario
    if (form.type === TYPE.DISTRIBUTOR) {
      if (!form.address)
        return onError('La dirección es obligatoria');

      if (!form.state_id)
        return onError('El estado es obligatorio');

      if (!form.township_id)
        return onError('El municipio es obligatorio');

      if (!form.parish_id)
        return onError('La parroquia es obligatoria');
    }

    if (form.type === TYPE.SELLER) {
      if (!form.address)
        return onError('La dirección es obligatoria');

      if (!form.state_id)
        return onError('El estado es obligatorio');

      if (!form.township_id)
        return onError('El municipio es obligatorio');

      if (!form.parish_id)
        return onError('La parroquia es obligatoria');
    }

    if (form.type === TYPE.STORE) {
      if (!form.address)
        return onError('La dirección es obligatoria');

      if (!form.state_id)
        return onError('El estado es obligatorio');

      if (!form.township_id)
        return onError('El municipio es obligatorio');

      if (!form.parish_id)
        return onError('La parroquia es obligatoria');
    }

    return true;
  }

  const onSubmit = async () => {
    if (!isValidForm()) return;

    setisLoading(true);

    try {
      const user = await AuthService.updateMarketingProfile(form);
      toast.success('Perfil actualizado correctamente');
      login(user);

    } catch (error) {
      onError(String(error));
    }

    setisLoading(false);
  }

  useEffect(() => {
    getSelects();
    if (type === TYPE.SELLER) getDistributor(user?.marketing_distributor_id);
    if (type === TYPE.STORE) getSeller(user?.marketing_seller_id);
    if (user && user?.state_id) getTownships(user.state_id);
    if (user && user?.township_id) getParishes(user.township_id);
  }, []);

  return {
    distributor,
    form,
    isLoading,
    onChange,
    onSubmit,
    parishes,
    seller,
    states,
    townships,
  }
}
