import { deleteCookie, fetchWrapper, setCookie } from '../utils';

const baseUrl = process.env.REACT_APP_BASE_API + 'auth';

export async function login(form) {
  const url = `${baseUrl}/login/web`;
  const session = await fetchWrapper.post(url, form);
  setCookie(process.env.REACT_APP_SESSION_NAME, session?.user?.token || '', 1);
  setCookie(`${process.env.REACT_APP_SESSION_NAME}-rset`, btoa(btoa(session?.user?.user?.level_id)) || '', 1);
  return session;
}

export async function logout() {
  removeSession();
}

export function removeSession() {
  deleteCookie(process.env.REACT_APP_SESSION_NAME);
  deleteCookie(`${process.env.REACT_APP_SESSION_NAME}-rset`);
  window.location.replace(process.env.REACT_APP_BASENAME || '/');
}

export async function getProfile() {
  return await fetchWrapper.get(`${baseUrl}/get/profile`);
}

export async function changePassword(form) {
  return await fetchWrapper.post(`${baseUrl}/profile/password`, form);
}

export async function updateMarketingProfile(form) {
  return await fetchWrapper.post(`${baseUrl}/profile/marketing`, form);
}

export async function recover(form) {
  return await fetchWrapper.post(`${baseUrl}/recover/web`, form);
}

export async function recoverPassword(form) {
  return await fetchWrapper.post(`${baseUrl}/reset`, form);
}