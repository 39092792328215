import React, { useState } from 'react';
import { Button, Divider, Input } from '@nextui-org/react';
import { IconEye, IconEyeOff } from '@tabler/icons-react';
import toast from 'react-hot-toast';
import { AuthService } from '../../../services';

export const ProfilePassword = () => {

  const [shownCurrent, setShownCurrent] = useState(false);
  const [shownNew, setShownNew] = useState(false);
  const { form, isLoading, onChange, submit } = useForm();

  return (
    <div>
      <h4 className="font-semibold text-large">Contraseña</h4>
      <p className="text-sm text-gray-500">Establece tu nueva contraseña</p>
      <Divider className="mt-2 mb-4" />
      <form className="grid gap-4 mb-4 max-w-sm" onSubmit={submit}>
        <Input
          label="Contraseña actual"
          labelPlacement="outside"
          placeholder=" "
          variant="bordered"
          classNames={{ inputWrapper: 'border-1', label: 'font-semibold' }}
          value={form.current_password}
          onValueChange={v => onChange(v, 'current_password')}
          type={shownCurrent ? 'text' : 'password'}
          endContent={
            <PasswordToggler show={shownCurrent} onPress={() => setShownCurrent(!shownCurrent)} />
          }
        />
        <Input
          label="Nueva contraseña"
          labelPlacement="outside"
          placeholder=" "
          variant="bordered"
          classNames={{ inputWrapper: 'border-1', label: 'font-semibold' }}
          value={form.password}
          onValueChange={v => onChange(v, 'password')}
          type={shownNew ? 'text' : 'password'}
          endContent={
            <PasswordToggler show={shownNew} onPress={() => setShownNew(!shownNew)} />
          }
        />
        <Input
          label="Confirmar nueva contraseña"
          labelPlacement="outside"
          placeholder=" "
          variant="bordered"
          classNames={{ inputWrapper: 'border-1', label: 'font-semibold' }}
          value={form.password_confirmation}
          onValueChange={v => onChange(v, 'password_confirmation')}
          type={shownNew ? 'text' : 'password'}
          endContent={
            <PasswordToggler show={shownNew} onPress={() => setShownNew(!shownNew)} />
          }
        />
        <div>
          <Button type="submit" color="primary" isDisabled={isLoading} isLoading={isLoading}>
            Guardar
          </Button>
        </div>
      </form>
    </div>
  )
}

const PasswordToggler = ({ show, onPress }) => (
  <button className="focus:outline-none" type="button" onClick={onPress}>
    {show
      ? <IconEyeOff className="w-6 h-6" />
      : <IconEye className="w-6 h-6" />
    }
  </button>
)

const useForm = () => {
  const INITIAL_FORM = {
    current_password: '',
    password: '',
    password_confirmation: '',
  };

  const [canFetch, setCanFetch] = useState(true);
  const [form, setForm] = useState({ ...INITIAL_FORM });

  const onError = (msg) => {
    toast.error(msg);
    return false;
  }

  const onChange = (value, target) => {
    setForm(s => ({ ...s, [target]: value }));
  }

  const isValidForm = () => {
    if (!form.current_password)
      return onError('Debe indicar la contraseña actual');

    if (!form.password)
      return onError('Debe indicar la nueva contraseña');

    if (form.password !== form.password_confirmation)
      return onError('Las contraseñas no coinciden');

    return true;
  }

  const submit = async (event) => {
    event.preventDefault();

    if (!canFetch || !isValidForm()) return;
    setCanFetch(false);

    try {
      await AuthService.changePassword(form);
      toast.success(`Contraseña actualizada con éxito`);
      setForm({ ...INITIAL_FORM });
      setCanFetch(true);
      return true;

    } catch (error) {
      onError(String(error));
      setCanFetch(true);
      return false;
    }
  }

  return {
    form,
    isLoading: !canFetch,
    onChange,
    submit,
  };
}

export default ProfilePassword;