/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { Constants, fromStorage } from '../../../../utils';
import { ReportsService } from '../../../../services/marketing';

export const useFetchTable = () => {
  const initialFilters = {
    page: 1,
    perPage: Constants.PER_PAGE,
    id: '',
    search: '',
    since: null,
    until: null,
  };

  const initialPagination = {
    page: 1,
    pages: 1,
    total: 0,
    perPage: Constants.PER_PAGE,
  };

  const [data, setData] = useState([]);
  const [canFetch, setCanFetch] = useState(true);
  const [filters, setFilters] = useState(initialFilters);
  const [pagination, setPagination] = useState(initialPagination);

  const debounceTime = 500;
  const debounce = useRef();

  const fetchData = async () => {
    if (!canFetch) return;
    setCanFetch(false);

    try {
      const response = await ReportsService.reports.findAllCommissions(filters);
      const { data, ...rest } = response;

      setData(data);
      setPagination(rest);
      setCanFetch(true);

    } catch (error) {
      setData([]);
      onError(String(error));
    }
  }

  const print = async () => {
    setCanFetch(false);
    try {
      const res = await ReportsService.reports.downloadAllCommissions(filters);
      const el = document.createElement('a');
      el.href = fromStorage(res.url);
      el.download = 'Reporte de Comisiones';
      el.target = '_blank';
      el.click();
      el.remove();

    } catch (error) {
      onError(String(error));
    }
    setCanFetch(true);
  }

  const getCurrentPagination = () => {
    // Truco para obtener el estado actualizado (pagination es mantenida con el estado actual por el componente Pagination)
    let pag;
    setPagination(s => {
      pag = s;
      return s;
    });
    return pag;
  }

  const canResetFilter = () => {
    const { page, perPage, ...initial } = initialFilters;
    const { page: _, perPage: __, ...current } = filters;
    const initFilter = JSON.stringify(initial);
    const currFilter = JSON.stringify(current);
    return initFilter !== currFilter;
  }

  const onError = (msg) => toast.error(msg);

  const reload = (inSamePage = false) => {
    setCanFetch(true);
    if (!inSamePage) setFilters(initialFilters);
    else fetchData();
  }

  const goToPage = (page) => {
    const pagination = getCurrentPagination();
    if (page >= 1 && page <= pagination.pages && page !== pagination.page) {
      setCanFetch(true);
      setFilters({ ...filters, page });
    }
  }

  const changePerPage = (perPage) => {
    setCanFetch(true);
    setFilters({ ...filters, perPage });
  }

  const filterBy = (value, target) => {
    if (debounce.current) clearTimeout(debounce.current);
    debounce.current = setTimeout(() => {
      setCanFetch(true);
      setFilters({ ...filters, page: 1, [target]: value });
    }, debounceTime);
  }

  const getUser = (item, type) => {
    return item?.commissions?.find(x => x.user?.marketing_type === type)?.user;
  }
  
  const getCommissionTotal = (item, id) => {
    return item?.commissions?.filter(x => x.user_id === id)?.map(x => x.amount)?.reduce((a,b) => a + b, 0);
  }

  useEffect(() => {
    fetchData();
  }, [filters]);

  return {
    canResetFilter: canResetFilter(),
    changePerPage,
    data,
    filterBy,
    filters,
    getCommissionTotal,
    getUser,
    goToPage,
    isLoading: !canFetch,
    pagination,
    print,
    reload,
  }
}