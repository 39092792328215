import { fetchWrapper, objectToQueryParams } from '../../utils';

const baseUrl = process.env.REACT_APP_BASE_API + 'marketing/distributor/settings';

export const findAllProducts = async (filters) => {
  const url = `${baseUrl}?${objectToQueryParams(filters)}`;
  return await fetchWrapper.get(url);
}

export const updateProducts = async (form) => {
  return await fetchWrapper.patch(baseUrl, form);
}