import { fetchWrapper, objectToQueryParams } from '../../utils';

export class reports {
  static baseUrl = process.env.REACT_APP_BASE_API + 'marketing/reports';

  static async findAllCommissions(filters) {
    const url = `${this.baseUrl}/commissions?${objectToQueryParams(filters)}`;
    return await fetchWrapper.get(url);
  }

  static async downloadAllCommissions(filters) {
    const url = `${this.baseUrl}/commissions/download?${objectToQueryParams(filters)}`;
    return await fetchWrapper.get(url);
  }

  static async findAllSales(filters) {
    const url = `${this.baseUrl}/sales?${objectToQueryParams(filters)}`;
    return await fetchWrapper.get(url);
  }

  static async downloadAllSales(filters) {
    const url = `${this.baseUrl}/sales/download?${objectToQueryParams(filters)}`;
    return await fetchWrapper.get(url);
  }
}