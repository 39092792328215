import React from 'react';
import { Button, Checkbox, Divider, Input, Select, SelectItem, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow } from '@nextui-org/react';
import { IconX } from '@tabler/icons-react';
import { Constants, sanitizeToNumbers } from '../../../../../utils';
import { ProductSelector } from '../components';
import { useForm } from '.';

const MarketingCreateUserSeller = ({ user }) => {
  const { distributors, documentTypes, form, isEditing, isLoading, onAddProductsCommissions, onChange, onChangeProductsCommissions, onRemoveProductsCommissions, onSubmit, parishes, phoneCodes, products, productsCommissions, states, townships } = useForm(Constants.USER.MARKETING_TYPE.SELLER, user);

  return (
    <div className="max-w-screen-lg mx-auto flex flex-col bg-white p-4 rounded-lg">
      <h1 className="text-primary text-2xl font-bold text-center mb-8">
        { isEditing ? '' : 'Registro de' } Usuario Vendedor
      </h1>

      <div className="grid grid-cols-3 gap-4 items-start">
        <Input
          label="Nombre del vendedor"
          labelPlacement="outside"
          placeholder=" "
          classNames={{ inputWrapper: 'border-1' }}
          variant="bordered"
          value={form.name}
          onValueChange={v => onChange(v, 'name')}
        />
        <div className="flex">
          <Select
            label="Cédula de identidad"
            labelPlacement="outside"
            variant="bordered"
            placeholder=" "
            classNames={{
              base: 'w-1/3',
              trigger: 'border-1 border-r-0 rounded-tr-none rounded-br-none',
              label: 'text-nowrap overflow-visible',
            }}
            disallowEmptySelection={true}
            selectedKeys={!!documentTypes.length && !!form.dniType ? [form.dniType] : []}
            onSelectionChange={v => onChange(v.currentKey, 'dniType')}
          >
            {documentTypes.map((item) => (
              <SelectItem key={item.value} value={item.value}>
                { item.label }
              </SelectItem>
            ))}
          </Select>
          <Input
            label=" "
            labelPlacement="outside"
            variant="bordered"
            classNames={{ base: 'w-2/3', inputWrapper: 'border-1 border-l-0 rounded-tl-none rounded-bl-none' }}
            value={form.dni}
            onValueChange={v => onChange(sanitizeToNumbers(v), 'dni')}
          />
        </div>
        <div className="flex">
          <Select
            label="Teléfono"
            labelPlacement="outside"
            variant="bordered"
            placeholder=" "
            classNames={{
              base: 'w-1/3',
              trigger: 'border-1 border-r-0 rounded-tr-none rounded-br-none',
              label: 'text-nowrap overflow-visible',
            }}
            disallowEmptySelection={true}
            selectedKeys={!!phoneCodes.length && !!form.phoneCode ? [form.phoneCode] : []}
            onSelectionChange={v => onChange(v.currentKey, 'phoneCode')}
          >
            {phoneCodes.map((item) => (
              <SelectItem key={item.value} value={item.value}>
                { item.label }
              </SelectItem>
            ))}
          </Select>
          <Input
            label=" "
            labelPlacement="outside"
            variant="bordered"
            classNames={{ base: 'w-2/3', inputWrapper: 'border-1 border-l-0 rounded-tl-none rounded-bl-none' }}
            value={form.phone}
            onValueChange={v => onChange(sanitizeToNumbers(v), 'phone')}
          />
        </div>

        <Input
          label="Fecha de nacimiento"
          labelPlacement="outside"
          variant="bordered"
          type="date"
          classNames={{ inputWrapper: 'border-1' }}
          value={form.birthdate}
          onValueChange={v => onChange(v, 'birthdate')}
        />
        <Input
          label="Correo electrónico"
          labelPlacement="outside"
          variant="bordered"
          placeholder=" "
          classNames={{ inputWrapper: 'border-1' }}
          value={form.email}
          onValueChange={v => onChange(v, 'email')}
        />
        <Input
          label="Actividad económica (Ramo)"
          labelPlacement="outside"
          variant="bordered"
          placeholder=" "
          classNames={{ inputWrapper: 'border-1' }}
          value={form.economic_activity}
          onValueChange={v => onChange(v, 'economic_activity')}
        />

        <div className="col-span-3">
          <Input
            label="Dirección"
            labelPlacement="outside"
            placeholder=" "
            variant="bordered"
            classNames={{ inputWrapper: 'border-1' }}
            value={form.address}
            onValueChange={v => onChange(v, 'address')}
          />
        </div>

        <Select
          label="Estado"
          labelPlacement="outside"
          variant="bordered"
          placeholder=" "
          classNames={{ trigger: 'border-1' }}
          disallowEmptySelection={true}
          selectedKeys={!!states.length && !!form.state_id ? [form.state_id] : []}
          onSelectionChange={v => onChange(v.currentKey, 'state_id')}
        >
          {states.map((item) => (
            <SelectItem key={item.value} value={item.value}>
              { item.label }
            </SelectItem>
          ))}
        </Select>
        <Select
          label="Municipio"
          labelPlacement="outside"
          variant="bordered"
          placeholder=" "
          classNames={{ trigger: 'border-1' }}
          disallowEmptySelection={true}
          selectedKeys={!!townships.length && !!form.township_id ? [form.township_id] : []}
          onSelectionChange={v => onChange(v.currentKey, 'township_id')}
        >
          {townships.map((item) => (
            <SelectItem key={item.value} value={item.value}>
              { item.label }
            </SelectItem>
          ))}
        </Select>
        <Select
          label="Parroquia"
          labelPlacement="outside"
          variant="bordered"
          placeholder=" "
          classNames={{ trigger: 'border-1' }}
          disallowEmptySelection={true}
          selectedKeys={!!parishes.length && !!form.parish_id ? [form.parish_id] : []}
          onSelectionChange={v => onChange(v.currentKey, 'parish_id')}
        >
          {parishes.map((item) => (
            <SelectItem key={item.value} value={item.value}>
              { item.label }
            </SelectItem>
          ))}
        </Select>
      </div>

      <Divider className="mt-6 mb-2" />

      <h1 className="text-primary text-lg font-bold mb-2">
        Asignar a:
      </h1>

      <Select
        label="Seleccionar distribuidor"
        labelPlacement="outside"
        placeholder=" "
        variant="bordered"
        classNames={{ base: 'w-full sm:max-w-[20rem]', trigger: 'border-1' }}
        disallowEmptySelection={true}
        selectedKeys={!!distributors.length && !!form.marketing_distributor_id ? [form.marketing_distributor_id] : []}
        onSelectionChange={v => onChange(v.currentKey, 'marketing_distributor_id')}
      >
        {distributors.map((item) => (
          <SelectItem key={item.value} value={item.value}>
            { item.label }
          </SelectItem>
        ))}
      </Select>

      <Divider className="mt-6 mb-2" />

      <h1 className="text-primary text-lg font-bold mb-2">
        Autorizado para:
      </h1>

      <Checkbox
        isSelected={form.can_assign_qr}
        onValueChange={() => onChange(!form.can_assign_qr, 'can_assign_qr')}
      >
        Asignar códigos QR a comercios afiliados, para venta de:
      </Checkbox>

      <div className="ml-8 flex flex-col gap-2 mt-2">
        {/* <Checkbox
          isSelected={form.can_sell_all_products}
          onValueChange={() => onChange(true, 'can_sell_all_products')}
          isDisabled={!form.can_assign_qr}
        >
          Todos los productos
        </Checkbox> */}

        <Checkbox
          isSelected={!form.can_sell_all_products}
          onValueChange={() => onChange(false, 'can_sell_all_products')}
          isDisabled={!form.can_assign_qr}
        >
          Productos asignados para la venta
        </Checkbox>

        <ProductSelector
          products={products}
          selectedProducts={productsCommissions.map(x => x.product_id)}
          onSubmit={onAddProductsCommissions}
          isDisabled={!form.can_assign_qr || form.can_sell_all_products}
        />

        {!!productsCommissions.length && (
          <Table
            aria-label="Productos autorizados para vender"
            classNames={{ base: 'mt-4', wrapper: 'p-0 border', th: '!bg-primary !text-white' }}
          >
            <TableHeader>
              <TableColumn>Productos seleccionados</TableColumn>
              <TableColumn>% Comisión Global</TableColumn>
              <TableColumn>% Comisión Distribuidor</TableColumn>
              <TableColumn>% Comisión Vendedor</TableColumn>
              <TableColumn />
            </TableHeader>
            <TableBody items={productsCommissions}>
              {productsCommissions.map(item => {
                const commissions = products.find(x => x.value === item.product_id);
                return (
                  <TableRow key={item.index}>
                    <TableCell>{ item?.name }</TableCell>
                    <TableCell>{ commissions?.commission_perc }</TableCell>
                    <TableCell>{ commissions?.commission_perc_distributor }</TableCell>
                    <TableCell>
                      <Input
                        classNames={{ base: 'w-40', inputWrapper: 'border-1 bg' }}
                        variant="bordered"
                        type="number"
                        max={100}
                        min={0}
                        step={0.01}
                        value={item.commission_perc_seller}
                        onValueChange={v => onChangeProductsCommissions(v, 'commission_perc_seller', item.index)}
                        startContent={(<span>%</span>)}
                      />
                    </TableCell>
                    <TableCell>
                      <div className="flex justify-end">
                        <IconX
                          className="cursor-pointer"
                          onClick={() => onRemoveProductsCommissions(item.index)}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        )}
      </div>

      <Divider className="mt-6 mb-2" />

      <div className="mt-8 mb-4 flex justify-center">
        <Button
          isLoading={isLoading}
          disabled={isLoading}
          color="primary"
          onPress={onSubmit}
        >
          Guardar
        </Button>
      </div>
    </div>
  )
}

export default MarketingCreateUserSeller;
