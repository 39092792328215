import React, { useContext } from 'react';
import { Button } from '@nextui-org/react';
import { IconFileDownload, IconPrinter } from '@tabler/icons-react';
import { AuthContext } from '../../../context';
import { QRImage } from '../../../components';
import { Logo } from '../../../assets/images';
import { fromPhotos } from '../../../utils';

const IntermediaryHome = () => {
  const { user } = useContext(AuthContext);

  const download = async () => {
    const link = document.createElement('a');
    link.href = fromPhotos(user.marketing_qr_file);
    link.download = true;
    link.target = '_blank';
    link.click();
    link.remove();
  }

  const print = () => {
    const printContent = document.getElementById('qr-image').innerHTML;
    const printArea = document.getElementById('print-area');
    printArea.innerHTML = printContent;
    window.print();
    printArea.innerHTML = '';
  }

  return (
    <div className="mx-auto max-w-max">
      <div className="mx-auto bg-white p-4 rounded-lg shadow-lg">
        <div id="qr-image">
          <img src={Logo} className="w-36 object-contain mx-auto hidden printable" alt="logo" />

          <div className="mt-4 flex justify-center items-center gap-4">
            <QRImage
              image={fromPhotos(user.marketing_qr_file)}
              showExpand
              name={user.fullName}
            />
          </div>
        </div>

        <div className="mt-8 flex justify-center items-center gap-4">
          <Button
            color="primary"
            className="font-semibold pl-2"
            startContent={<IconFileDownload />}
            onClick={download}
          >
            Descargar
          </Button>

          <Button
            color="primary"
            className="font-semibold pl-2"
            variant="bordered"
            startContent={<IconPrinter />}
            onClick={print}
          >
            Imprimir
          </Button>
        </div>
      </div>
    </div>
  );
}

export default IntermediaryHome;
