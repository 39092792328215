/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import { Constants } from '../../../utils';
import { AuthContext } from '../../../context';
import { DistributorCommissionsReport, IntermediaryCommissionsReport, SellerCommissionsReport, StoreCommissionsReport } from './tables';

const TYPE = Constants.USER.MARKETING_TYPE;

const MarketingCommissionsReport = () => {
  const { user } = useContext(AuthContext);

  return (
    <>
      {user?.marketing_type === TYPE.DISTRIBUTOR && <DistributorCommissionsReport />}
      {user?.marketing_type === TYPE.SELLER && <SellerCommissionsReport />}
      {user?.marketing_type === TYPE.STORE && <StoreCommissionsReport />}
      {user?.marketing_type === TYPE.INTERMEDIARY && <IntermediaryCommissionsReport />}
    </>
  )
}

export default MarketingCommissionsReport;
