/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Button, Input, Pagination, Spinner, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow } from '@nextui-org/react';
import { IconSearch } from '@tabler/icons-react';
import moment from 'moment';
import { Constants, formatAmount } from '../../../../utils';
import { useFetchTable } from './use-fetch-commissions';

const TYPE = Constants.USER.MARKETING_TYPE;

export const StoreCommissionsReport = () => {
  const { canResetFilter, data, filterBy, getCommissionTotal, getUser, goToPage, isLoading, pagination, print, reload } = useFetchTable();

  return (
    <>
      {isLoading && (
        <div className="w-screen h-screen fixed inset-0 z-[70] flex justify-center items-center bg-white/30">
          <Spinner />
        </div>
      )}

      <Filters
        canResetFilter={canResetFilter}
        filterBy={filterBy}
        resetFilter={() => reload()}
      />

      <Table aria-label="Reporte de comisiones"
        topContent={
          <div className="flex flex-row justify-between items-center gap-4">
            <h3 className="text-xl font-medium text-primary">Reporte de Comisiones</h3>

            <div className="flex gap-2">
              <Button variant="bordered" onClick={print}>
                Exportar
              </Button>
            </div>
          </div>
        }
        topContentPlacement="inside"
      >
        <TableHeader>
          <TableColumn>N° de Venta</TableColumn>
          <TableColumn>Fecha de Venta</TableColumn>
          <TableColumn>Monto de Venta $</TableColumn>
          <TableColumn>Total Abonado $</TableColumn>
          <TableColumn>Comisión</TableColumn>
        </TableHeader>
        <TableBody items={data} emptyContent="No hay resultados">
          {(item) => {

            const saleAmount = item?.commissions[0]?.purchase_product?.price * item?.commissions[0]?.payment?.fees_qty;
            const user = getUser(item, TYPE.STORE);
            const amount = getCommissionTotal(item, user?.id);

            return (
              <TableRow key={item.id}>
                <TableCell>{ String(item.id).padStart(4,'0') }</TableCell>
                <TableCell>{ moment(item.created_at).format('DD/MM/YYYY') }</TableCell>
                <TableCell>{ formatAmount(saleAmount) }</TableCell>
                <TableCell>{ formatAmount(saleAmount) }</TableCell>
                <TableCell>{ amount ? formatAmount(amount) : '--' }</TableCell>
              </TableRow>
            )
          }}
        </TableBody>
      </Table>

      <div className="flex w-full justify-center mt-4">
        <Pagination
          showControls
          variant="bordered"
          page={pagination.page}
          total={pagination.pages}
          onChange={goToPage}
        />
      </div>
    </>
  )
}

const Filters = ({ canResetFilter, filterBy, resetFilter }) => {
  const initialFilter = {
    id: '',
    since: '',
    until: '',
  };
  const [form, setForm] = useState(initialFilter);

  const onChange = (value, target) => {
    setForm(s => ({ ...s, [target]: value }));
    filterBy(value, target);
  }

  return (
    <section className="mb-4 grid sm:flex grid-cols-2 lg:flex-row lg:flex-wrap items-end gap-4">
      <Input
        classNames={{
          base: 'w-full sm:max-w-[12rem]',
          inputWrapper: 'border-1 h-10',
        }}
        label="Buscar"
        labelPlacement="outside"
        placeholder="N° de Venta"
        startContent={<IconSearch />}
        variant="bordered"
        value={form.id}
        onValueChange={v => onChange(v, 'id')}
      />
      <Input
        type="date"
        classNames={{
          base: 'w-full sm:max-w-[10rem]',
          inputWrapper: 'border-1 h-10',
          input: `pr-0 text-${!!form.since ? '[]':'foreground-400'}`,
        }}
        label="Desde"
        labelPlacement="outside"
        placeholder=" "
        variant="bordered"
        value={form.since}
        onValueChange={v => onChange(v, 'since')}
      />
      <Input
        type="date"
        classNames={{
          base: 'w-full md:max-w-[10rem]',
          inputWrapper: 'border-1 h-10',
          input: `pr-0 text-${!!form.until ? '[]':'foreground-400'}`,
        }}
        label="Hasta"
        labelPlacement="outside"
        placeholder=" "
        variant="bordered"
        value={form.until}
        onValueChange={v => onChange(v, 'until')}
      />
      {canResetFilter && (
        <Button
          variant="light"
          className="text-primaryDark"
          onClick={() => {
            setForm(initialFilter);
            resetFilter();
          }}
        >
          Limpiar filtros
        </Button>
      )}
    </section>
  )
}