/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import { Constants } from '../../../utils';
import { AuthContext } from '../../../context';
import { DistributorForm, IntermediaryForm, SellerForm, StoreForm } from './forms';

const TYPE = Constants.USER.MARKETING_TYPE;

const Profile = () => {
  const { user } = useContext(AuthContext);

  return (
    <>
      {user?.marketing_type === TYPE.DISTRIBUTOR && <DistributorForm user={user} />}
      {user?.marketing_type === TYPE.SELLER && <SellerForm user={user} />}
      {user?.marketing_type === TYPE.STORE && <StoreForm user={user} />}
      {user?.marketing_type === TYPE.INTERMEDIARY && <IntermediaryForm user={user} />}
    </>
  )
}

export default Profile;
