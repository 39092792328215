import React, { useState } from 'react';
import { Button, Input } from '@nextui-org/react';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { IconEye, IconEyeOff } from '@tabler/icons-react';
import { AuthService } from '../../services';
import { Logo } from '../../assets/images';

const RecoverPassword = () => {
  const nav = useNavigate();
  const { code } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [form, setForm] = useState({
    code,
    password: '',
    password_confirmation: '',
  });

  const toggleVisibility = () => setIsVisible(!isVisible);

  const onChange = (value, target) => {
    setForm(s => ({ ...s, [target]: value }))
  }

  const isValidForm = () => {
    const onError = (msg) => {
      toast.error(msg);
      return false;
    }

    if (!form.code)
      return onError('El código es obligatorio');

    if (!form.password)
      return onError('La contraseña es obligatoria');

    if (!form.password_confirmation)
      return onError('Debe confirmar la contraseña');

    if (form.password !== form.password_confirmation)
      return onError('Las contraseñas no coinciden');

    return true;
  }

  const onSubmit = async (event) => {
    event.preventDefault();
    if (!isValidForm()) return;
    setIsLoading(true);

    try {
      const res = await AuthService.recoverPassword(form);

      if (res?.error) throw res.error;
      if (!res?.result) throw toast.error('Código inválido');

      toast.success('Contraseña actualizada con éxito');
      res?.redirect === 'app'
        ? window.location.replace(process.env.REACT_APP_WEB_APP)
        : nav('/');

    } catch (error) {
      //
    }
    setIsLoading(false);
  }

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img
          className="mx-auto h-32 w-auto"
          src={Logo}
          alt={process.env.REACT_APP_NAME}
        />
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Recuperar contraseña
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form className="space-y-6" onSubmit={onSubmit}>
          <div className="text-sm font-medium leading-6 flex flex-col gap-4">
            <Input
              label="Contraseña"
              labelPlacement="outside"
              variant="bordered"
              radius="sm"
              type={isVisible ? "text" : "password"}
              value={form.password}
              onValueChange={v => onChange(v, 'password')}
              endContent={
                <button className="focus:outline-none" type="button" onClick={toggleVisibility}>
                  {isVisible ? (
                    <IconEyeOff className="w-6 h-6" />
                  ) : (
                    <IconEye className="w-6 h-6" />
                  )}
                </button>
              }
            />

            <Input
              label="Confirmar contraseña"
              labelPlacement="outside"
              variant="bordered"
              radius="sm"
              type={isVisible ? "text" : "password"}
              value={form.password_confirmation}
              onValueChange={v => onChange(v, 'password_confirmation')}
              endContent={
                <button className="focus:outline-none" type="button" onClick={toggleVisibility}>
                  {isVisible ? (
                    <IconEyeOff className="w-6 h-6" />
                  ) : (
                    <IconEye className="w-6 h-6" />
                  )}
                </button>
              }
            />
          </div>

          <Button type="submit" color="primary" radius="sm" fullWidth isLoading={isLoading} onClick={onSubmit}>
            Guardar
          </Button>
        </form>
      </div>
    </div>
  )
}

export default RecoverPassword;