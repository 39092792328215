import { useState } from 'react';
import { Button, Checkbox, Popover, PopoverContent, PopoverTrigger } from '@nextui-org/react';
import { IconChevronDown } from '@tabler/icons-react';
import { useEffect } from 'react';

export const ProductSelector = ({ label, isDisabled, onSubmit, products, selectedProducts }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);

  const onChange = (id) => {
    const ids = selectedIds.includes(id)
      ? selectedIds.filter(x => x !== id)
      : selectedIds.concat([id]);
    setSelectedIds(ids);
  }

  const submit = () => {
    onSubmit(selectedIds.filter(x => !selectedProducts.includes(x)));
    setIsOpen(false);
  }

  useEffect(() => {
    setSelectedIds(selectedProducts);
  }, [selectedProducts]);

  return (
    <Popover placement="bottom" isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <PopoverTrigger>
        <Button
          className="w-80"
          variant="bordered"
          endContent={(
            <div className="flex flex-1 justify-end">
              <IconChevronDown className="w-4 h-4" />
            </div>
          )}
          onClick={() => setIsOpen(!isOpen)}
          isDisabled={isDisabled}
        >
          { label || 'Productos (Puedes seleccionar varios)' }
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-80">
        <div className="flex flex-col w-full pb-2">
          <Button
            color="primary"
            className="mt-2 mb-4"
            isDisabled={!selectedIds.length}
            onClick={submit}
          >
            Agregar
          </Button>
          {products.map((product, idx) => {
            const isSelected = selectedProducts.includes(product.value);
            return (
              <Checkbox
                key={idx}
                classNames={{ base: 'p-0 m-1 mx-0' }}
                isDisabled={isSelected}
                isSelected={isSelected || undefined}
                onClick={() => onChange(product.value)}
              >
                <span className="text-sm">{ product?.label }</span>
              </Checkbox>
            )
          })}
        </div>
      </PopoverContent>
    </Popover>
  )
}