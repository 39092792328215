import { IconCategory2, IconCreditCard, IconFirstAidKit, IconHome, IconShield, IconUsers, IconWallet, IconUserShield, IconUserCode, IconUser } from '@tabler/icons-react';
import { Constants } from '../utils';
import { IconClipboardData } from '@tabler/icons-react';

const perm = Constants.PERMISSIONS;
// const type = Constants.USER.MARKETING_TYPE;

// todos los items son para mostrar en el menú lateral
// excluyendo registros hidden: true y también en nested
// todos los items son para mostrar en el breadcrumb
// profile: para seccionar los items por permisos/niveles asignados, Moderadores/Marketing

export const MENU_ADMIN = [
  {
    name: 'Cambiar contraseña',
    href: '/profile',
    hidden: true,
  },
  {
    name: 'Inicio',
    href: '/',
    icon: IconHome,
  },
  {
    name: 'Moderadores',
    href: '/moderadores',
    icon: IconUserShield,
    profile: [
      perm.ADMINISTRATOR,
    ],
  },
  {
    name: 'Usuarios',
    href: '/usuarios',
    icon: IconUsers,
    profile: [
      perm.ADMINISTRATOR,
      perm.SUBSCRIPTION,
    ],
  },
  {
    name: 'Productos',
    href: '/productos',
    icon: IconShield,
    profile: [
      perm.ADMINISTRATOR,
      perm.SUBSCRIPTION,
    ],
    nested: [
      {
        name: 'Crear Producto',
        href: '/productos/nuevo',
      },
      {
        name: 'Editar Producto',
        href: '/productos/:id/editar',
      },
    ]
  },
  {
    name: 'Servicios',
    href: '/servicios',
    icon: IconCategory2,
    profile: [
      perm.ADMINISTRATOR,
      perm.SERVICES,
    ],
  },
  {
    name: 'Gestión de Servicios',
    href: '/solicitudes',
    icon: IconFirstAidKit,
    profile: [
      perm.ADMINISTRATOR,
      perm.SERVICES,
    ],
  },
  {
    name: 'Gestión de Pagos',
    href: '/pagos',
    icon: IconWallet,
    profile: [
      perm.ADMINISTRATION,
      perm.ADMINISTRATOR,
      perm.SUBSCRIPTION,
    ],
  },
  {
    name: 'Gestión de Suscripción',
    href: '/suscripciones',
    icon: IconCreditCard,
    profile: [
      perm.ADMINISTRATOR,
      perm.SUBSCRIPTION,
    ],
  },
  {
    name: 'Canales de Comercialización',
    icon: IconUserCode,
    profile: [
      perm.ADMINISTRATOR,
    ],
    modules: [
      {
        name: 'Usuarios',
        href: '/canales-comercializacion/intermediarios',
        nested: [
          {
            name: 'Nuevo Registro',
            href: '/canales-comercializacion/intermediarios/nuevo/alterno',
          },
          {
            name: 'Nuevo Registro',
            href: '/canales-comercializacion/intermediarios/nuevo',
          },
          {
            name: 'Editar Registro',
            href: '/canales-comercializacion/intermediarios/:id/editar',
          },
        ]
      },
      {
        name: 'Gestión de Comisiones',
        href: '/canales-comercializacion/comisiones',
      },
      {
        name: 'Reportes',
        modules: [
          {
            name: 'Reporte de Ventas',
            href: '/canales-comercializacion/reportes/ventas',
          },
          {
            name: 'Reporte de Comisiones',
            href: '/canales-comercializacion/reportes/comisiones',
          }
        ],
      }
    ],
  },

];

export const MENU_MARKETING = [
  {
    name: 'Cambiar contraseña',
    href: '/perfil/autenticacion',
    hidden: true,
  },
  {
    name: 'Inicio',
    href: '/',
    icon: IconHome,
  },
  {
    name: 'Perfil',
    href: '/perfil',
    icon: IconUser,
  },
  {
    name: 'Reportes',
    icon: IconClipboardData,
    modules: [
      {
        name: 'Reporte de Ventas',
        href: '/canales-comercializacion/reportes/ventas',
      },
      {
        name: 'Reporte de Comisiones',
        href: '/canales-comercializacion/reportes/comisiones',
      }
    ],
  },
];